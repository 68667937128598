import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "editor-plugins"
    }}>{`Editor plugins`}</h1>
    <p>{`The community has begun adding MDX syntax highlighting support for some editors!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/silvenon/vscode-mdx"
        }}>{`vscode`}</a>{`: VSCode`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/vim-mdx-js"
        }}>{`vim`}</a>{`: Vim`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jonsuh/mdx-sublime"
        }}>{`sublime`}</a>{`: Sublime`}</li>
    </ul>
    <h3 {...{
      "id": "editors-needing-support"
    }}>{`Editors needing support`}</h3>
    <ul>
      <li parentName="ul">{`Atom`}</li>
      <li parentName="ul">{`Emacs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtrack.jetbrains.com/issue/WEB-32599"
        }}>{`JetBrains IntelliJ/WebStorm`}</a></li>
    </ul>
    <hr></hr>
    <p>{`Original GitHub issue: `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/issues/119"
      }}>{`#119`}</a></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      